import PropTypes from 'prop-types';
import React from 'react';

import { root, statementStyle, statementTextStyle } from './index.module.scss';
import buildImageObj from '../../utils/build-image-obj';
import imageUrlFor from '../../utils/image-url-for';

const HomepageImageWithStatement = ({ image, statement }) => (
  <section className={root}>
    {image && image.asset && (
      <img
        src={imageUrlFor(buildImageObj(image)).width(1365).height(966).fit('crop').auto('format').quality(80).url()}
        alt={image.alt}
      />
    )}
    <div className={statementStyle}>
      <span className={statementTextStyle}>{statement}</span>
    </div>
  </section>
);

HomepageImageWithStatement.propTypes = {
  image: PropTypes.shape({
    alt: PropTypes.string,
    asset: PropTypes.shape({}),
  }).isRequired,
  statement: PropTypes.string.isRequired,
};

export default HomepageImageWithStatement;
