/* eslint-disable no-underscore-dangle */
import { graphql } from 'gatsby';
import compose from 'lodash/fp/compose';
import filter from 'lodash/fp/filter';
import map from 'lodash/fp/map';
import PropTypes from 'prop-types';
import React from 'react';

import CategoryGrid from '../../components/category-grid';
import Container from '../../components/container';
import ContentPage from '../../components/content-page';
import CopyFourImage from '../../components/copy-four-image';
import FooterNavigation from '../../components/footer-navigation';
import GraphQLErrorList from '../../components/graphql-error-list';
import HomepageContactSection from '../../components/homepage-contact-section';
import HomepageHeroProject from '../../components/homepage-hero-project';
import HomepageImageWithStatement from '../../components/homepage-image-with-statement';
import HomepageVideo from '../../components/homepage-video';
import HomepageVideoWithStatement from '../../components/homepage-video-with-statement';
import ImageWithCopy from '../../components/image-with-copy';
import LargeCopy from '../../components/large-copy';
import Layout from '../../containers/layout';
import mapEdgesToNodes from '../../utils/map-edges-to-nodes';
import OneImage from '../../components/one-image';
import PageTitle from '../../components/page-title';
import PostGrid from '../../components/post-list-grid';
import Quote from '../../components/quote';
import QuoteImage from '../../components/quote-image';
import SEO from '../../components/seo';
import SixImage from '../../components/six-image';
import Spacer from '../../components/spacer';
import ThreeArticle from '../../components/three-article';
import ThreeImage from '../../components/three-image';
import TwoColumnNotes from '../../components/two-column-notes';
import TwoImage from '../../components/two-image';

export const query = graphql`
  query PageTemplateQuery($id: String!) {
    route: sanityRoute(id: { eq: $id }) {
      slug {
        current
      }
      useSiteTitle
      page {
        ...PageInfo
      }
    }
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
    }
    # categories: allSanityCategory {
    #   edges {
    #     node {
    #       id
    #       title
    #       caption
    #       categoryImage {
    #         _key
    #         _type
    #         asset {
    #           _id
    #         }
    #         alt
    #         _rawAsset
    #         _rawHotspot
    #         _rawCrop
    #       }
    #       slug {
    #         _key
    #         _type
    #         current
    #       }
    #     }
    #   }
    # }
    posts: allSanityPost {
      edges {
        node {
          id
          title
          previewImage {
            _key
            _type
            asset {
              _id
            }
            alt
            _rawAsset
            _rawHotspot
            _rawCrop
          }
          slug {
            _key
            _type
            current
          }
        }
      }
    }
  }
`;

const Page = ({ children, data, errors }) => {
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const { site } = data;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.',
    );
  }

  const page = data.page || data.route.page;

  const content = compose(
    filter((item) => !item.disabled),
    map((item) => {
      let el = null;
      switch (item._type) {
        case 'pageTitle':
          el = <PageTitle key={item._key} title={item.title} />;
          break;
        case 'categoryGrid':
          el = <CategoryGrid key={item._key} categories={item.categories} />;
          break;
        case 'postGrid':
          el = <PostGrid key={item._key} posts={mapEdgesToNodes(data.posts)} />;
          break;
        case 'imageWithCopy':
          el = <ImageWithCopy key={item._key} {...item} />;
          break;
        case 'largeCopy':
          el = <LargeCopy key={item._key} {...item} />;
          break;
        case 'copyFourImage':
          el = <CopyFourImage key={item._key} {...item} />;
          break;
        case 'sixImage':
          el = <SixImage key={item._key} {...item} />;
          break;
        case 'threeArticle':
          el = <ThreeArticle key={item._key} {...item} />;
          break;
        case 'threeImage':
          el = <ThreeImage key={item._key} {...item} />;
          break;
        case 'twoImage':
          el = <TwoImage key={item._key} {...item} />;
          break;
        case 'quoteWithImage':
          el = <QuoteImage key={item._key} {...item} />;
          break;
        case 'quote':
          el = <Quote key={item._key} {...item} />;
          break;
        case 'twoColumnNotes':
          el = <TwoColumnNotes key={item._key} {...item} />;
          break;
        case 'oneImage':
          el = <OneImage key={item._key} {...item} />;
          break;
        case 'footerNavigation':
          el = <FooterNavigation key={item._key} />;
          break;
        case 'homepageVideo':
          el = <HomepageVideo key={item._key} {...item} />;
          break;
        case 'homepageImageWithStatement':
          el = <HomepageImageWithStatement key={item._key} {...item} />;
          break;
        case 'homepageHeroProject':
          el = <HomepageHeroProject key={item._key} {...item} />;
          break;
        case 'homepageVideoWithStatement':
          el = <HomepageVideoWithStatement key={item._key} {...item} />;
          break;
        case 'homepageContactSection':
          el = <HomepageContactSection key={item._key} {...item} />;
          break;
        case 'spacer':
          el = <Spacer key={item._key} />;
          break;
        default:
          el = null;
      }
      return el;
    }),
  )(page._rawContent || []);

  const menuItems = page.navMenu && (page.navMenu.items || []);
  const pageTitle = data.route && !data.route.useSiteTitle && page.title;

  if (data.route === null) {
    return (
      <Layout menuItems={menuItems} isHomepage backgroundColour={page.backgroundColour}>
        <SEO
          title={site.title}
          description={site.description}
          keywords={site.keywords}
          backgroundColour={page.backgroundColour}
        />
        <Container>
          {content}
          {children}
        </Container>
      </Layout>
    );
  }
  return (
    <Layout menuItems={menuItems} isHomepage={false} backgroundColour={page.backgroundColour}>
      <SEO
        title={pageTitle}
        description={site.description}
        keywords={site.keywords}
        backgroundColour={page.backgroundColour}
      />
      <ContentPage>{content}</ContentPage>
    </Layout>
  );
};

Page.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
  data: PropTypes.shape({
    page: PropTypes.shape({
      backgroundColour: PropTypes.string,
    }),
    posts: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.object),
    }),
    route: PropTypes.shape({
      page: PropTypes.shape({}),
      slug: PropTypes.shape({
        current: PropTypes.string,
      }),
      useSiteTitle: PropTypes.bool,
    }),
    site: PropTypes.shape({
      description: PropTypes.string,
      keywords: PropTypes.arrayOf(PropTypes.string),
      title: PropTypes.string,
    }),
  }),
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      message: PropTypes.string,
    }),
  ),
};

Page.defaultProps = {
  children: null,
  data: {},
  errors: null,
};

export default Page;
