import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { hover, image, root, titleStyle } from './index.module.scss';
import buildImageObj from '../../../../utils/build-image-obj';
import GatsbyLink from '../../../gatsby-link';
import imageUrlFor from '../../../../utils/image-url-for';

const Category = ({ caption, categoryImage, slug, title }) => {
  const [isMouseOver, setIsMouseOver] = useState(false);

  const toggleMouseOver = () => {
    setIsMouseOver(!isMouseOver);
  };

  return (
    <GatsbyLink
      className={root}
      to={`/projects/${slug.current}`}
      onMouseEnter={toggleMouseOver}
      onMouseLeave={toggleMouseOver}
    >
      <div className={image}>
        {categoryImage && categoryImage.asset && (
          <img
            src={imageUrlFor(buildImageObj(categoryImage)).width(431).height(298).quality(80).url()}
            alt={categoryImage.alt}
          />
        )}
        <div className={isMouseOver ? hover : titleStyle}>
          <h3>{isMouseOver ? caption : title}</h3>
        </div>
      </div>
    </GatsbyLink>
  );
};

Category.propTypes = {
  caption: PropTypes.string.isRequired,
  categoryImage: PropTypes.shape({
    alt: PropTypes.string,
    asset: PropTypes.shape({}),
  }).isRequired,
  slug: PropTypes.shape({
    current: PropTypes.string,
  }).isRequired,
  title: PropTypes.string.isRequired,
};

export default Category;
