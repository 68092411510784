import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { root } from './index.module.scss';
import Category from './components/category';

const CategoryGrid = (props) => {
  const { categories } = props;

  return (
    <section className={root}>
      {_.map(categories, (item, index) => (
        <Category
          key={index}
          caption={item.caption}
          categoryImage={item.categoryImage}
          slug={item.slug}
          title={item.title}
        />
      ))}
      <div />
    </section>
  );
};

CategoryGrid.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default CategoryGrid;
