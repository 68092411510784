import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { rootFourGrid, rootSixGrid } from './index.module.scss';
import PostPreview from './components/post-preview';

const PostListGrid = (props) => {
  const { posts } = props;
  return (
    <div className={posts.length === 6 ? rootSixGrid : rootFourGrid}>
      {posts && _.map(posts, (post, index) => <PostPreview key={index} {...post} />)}
    </div>
  );
};

PostListGrid.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PostListGrid;
