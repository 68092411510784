import PropTypes from 'prop-types';
import React from 'react';

import { bgVideo, featureText, featureVideo, root } from './index.module.scss';
import Video from '../video';

const HomepageVideoWithStatement = ({ statement, muxVideo }) => (
  <div className={root}>
    <Video playbackId={muxVideo.asset.playbackId} className={bgVideo} />
    <div className={featureVideo}>
      <span className={featureText}>{statement}</span>
    </div>
  </div>
);

HomepageVideoWithStatement.propTypes = {
  muxVideo: PropTypes.shape({
    asset: PropTypes.shape({
      playbackId: PropTypes.string,
    }),
  }).isRequired,
  statement: PropTypes.string.isRequired,
};

export default HomepageVideoWithStatement;
