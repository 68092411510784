import PropTypes from 'prop-types';
import React from 'react';

import { bgVideo, bigMint, root } from './index.module.scss';
import mLogoLarge from '../../assets/images/large_m_symbol.svg';
import Video from '../video';

const HomepageVideo = ({ muxVideo }) => (
  <div className={root} id="home">
    <Video playbackId={muxVideo.asset.playbackId} className={bgVideo} />
    <img className={bigMint} src={mLogoLarge} alt="Mint Design" />
  </div>
);

HomepageVideo.propTypes = {
  muxVideo: PropTypes.shape({
    asset: PropTypes.shape({
      playbackId: PropTypes.string,
    }),
  }).isRequired,
};

export default HomepageVideo;
