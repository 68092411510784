import { RiFacebookBoxFill, RiInstagramFill, RiLinkedinBoxFill } from 'react-icons/ri';
import PropTypes from 'prop-types';
import React from 'react';

import { contactText, root } from './index.module.scss';
import buildImageObj from '../../utils/build-image-obj';
import imageUrlFor from '../../utils/image-url-for';

const HomepageContactSection = ({ image }) => (
  <section className={root} id="contact">
    {image && image.asset && (
      <img
        src={imageUrlFor(buildImageObj(image)).width(1365).height(966).fit('crop').auto('format').quality(80).url()}
        alt={image.alt}
      />
    )}
    <div className={contactText}>
      <p>Mint Landscape Design</p>
      Studio 14 Kindred Studios
      <br />
      3 Harris St Yarraville, Vic 3013
      <br />P &mdash; <a href="tel:0394340525">03 9434 0525</a>
      <br />E &mdash; <a href="mailto:info@mintdesign.net.au">info@mintdesign.net.au</a>
      <br />
      ABN 86 329 517 256
      <br />
      <br />
      <a href="https://www.instagram.com/mintdesignau/" target="_blank" rel="noreferrer">
        <RiInstagramFill size="2rem" />
      </a>
      {` `}
      <a href="https://www.facebook.com/MINTLandscapeDesign" target="_blank" rel="noreferrer">
        <RiFacebookBoxFill size="2rem" />
      </a>
      {` `}
      <a href="https://www.linkedin.com/company/mintdesign/" target="_blank" rel="noreferrer">
        <RiLinkedinBoxFill size="2rem" />
      </a>
    </div>
  </section>
);

HomepageContactSection.propTypes = {
  image: PropTypes.shape({
    alt: PropTypes.string,
    asset: PropTypes.shape({}),
  }).isRequired,
};

export default HomepageContactSection;
