import PropTypes from 'prop-types';
import React from 'react';

import { col, copyStyle, root } from './index.module.scss';

const LargeCopy = (props) => {
  const { copy } = props;

  return (
    <section className={root}>
      <div className={col}>
        <span className={copyStyle}>{copy}</span>
      </div>
    </section>
  );
};

LargeCopy.propTypes = {
  copy: PropTypes.string.isRequired,
};

export default LargeCopy;
