import PropTypes from 'prop-types';
import React from 'react';

import { link, root, statementStyle } from './index.module.scss';
import buildImageObj from '../../utils/build-image-obj';
import GatsbyLink from '../gatsby-link';
import imageUrlFor from '../../utils/image-url-for';

const HomepageHeroProject = ({ project, statement }) => {
  const { categories, heroImage, slug } = project[0];
  const projectLink = `/projects/${categories[0].slug.current}/${slug.current}`;

  return (
    <section className={root}>
      {heroImage && heroImage.asset && (
        <img
          src={imageUrlFor(buildImageObj(heroImage))
            .width(1365)
            .height(966)
            .fit('crop')
            .auto('format')
            .quality(80)
            .url()}
          alt={heroImage.alt}
        />
      )}
      <div className={statementStyle}>
        <span>{statement}</span>
        <GatsbyLink to={projectLink} className={link}>
          See project
        </GatsbyLink>
      </div>
    </section>
  );
};

HomepageHeroProject.propTypes = {
  project: PropTypes.arrayOf(
    PropTypes.shape({
      categories: PropTypes.arrayOf(
        PropTypes.shape({
          slug: PropTypes.shape({
            current: PropTypes.string,
          }),
        }),
      ),
      heroImage: PropTypes.shape({
        alt: PropTypes.string,
        asset: PropTypes.shape({}),
      }),
      slug: PropTypes.shape({
        current: PropTypes.string,
      }),
    }),
  ).isRequired,
  statement: PropTypes.string.isRequired,
};

export default HomepageHeroProject;
